V2.Module.General.Navigation = {

	'controlMenu' : function(){
		$('ul.v2 > li').on('mousedown',function(){
			var $this = $(this);
			if(!$this.hasClass('open')){
				$('ul.v2 > li').removeClass('open');
				$this.addClass('open');
			}else{
				$('ul.v2 > li').removeClass('open');
			}
		});

		$('ul.v2 li ul li').on('mousedown',function(e){
			e.stopPropagation();
			
		})

		$('ul.v2 > li').on('mouseenter',function(){
			var _w = $(window).width();
			$('ul.v2 > li').each(function(){
				if($(this).hasClass('open') && _w > 768){
					$('ul.v2 > li').removeClass('open');
					return;
				}
			});
		});

		$('ul.v2').on('mouseleave',function(){
			var _w = $(window).width();
			if( _w >= 768){
				setTimeout(function(){
					$('ul.v2 > li').removeClass('open');
				},3000);
			}
		});
	}

};

$(document).ready(function(){
	V2.Module.General.Navigation.controlMenu();
	
	//dirty fix - make it clean when time
	setInterval(function(){		
		if( window.location.href.indexOf('weblog') != -1 && $('div.cke').size() > 0 && $('div.cke').css('position') == 'fixed'){
			$('div.cke').css('top','40px');
		}
	},500);

	if($('#shoutchat_holder').size() > 0){
		$('#pageholder').css({
			'padding-right' : "170px"
		});
	}

	$('body').addClass(location.href.split('/')[3]);

	//12 is count of datenbank sublevel LI's plus 1 extra for space
	if($('body').height() < ( $('ul.v2 li').height() * ( $('ul.v2 > li').size() + 12 ) ) && $(window).width() < 768){
		$('body').css('height', ( $('ul.v2 li').height() * ( $('ul.v2 > li').size() + 12) )+"px" )
	}

	// adding and removing classes for navigation depending on hover / end-of-hover events by user
	// is necessary because the desired behavior of navigation could not be achieved only by CSS
	$("#xxmenu_holder ul.v2").on("mouseleave", function () {
		$("#menu_logo_holder").removeClass('hover');
		$("#menu_logo_holder").addClass('not-hover');
	});

	$("#xxmenu_holder ul.v2").on("mouseenter", function () {
		$("#menu_logo_holder").removeClass('not-hover');
		$("#menu_logo_holder").addClass('hover');
	});

	if( $('.countdown').length != 0 ){
		$('.countdown').parents('li').prepend('<span>'+ $('.countdown').attr('data-days') +'</span>');
	}
	
});

$(window).on('resize',function(){
	if( $(this).width() > 768){
		$('body').removeClass('menu_mini_main');
	}
});

$(window).on('scroll',function(e){
	var _top  = $(this).scrollTop();	
	if(_top >=  $('#bodyid').offset().top + 40 && $(window).width() >= 768 && $(window).width() < 1200 && $('body').hasClass('sb_pos_fixed')){
		$('body').addClass('sidebar_pinned_scrolled_down');	
	}else{		
		$('body').removeClass('sidebar_pinned_scrolled_down');		
	}

});



