V2.Tool.Ajax = function( obj_options ){
	/*settig up URL after \Animexx\V2\View\ */
	this.url = ( typeof obj_options.url !== "undefined" ) ? obj_options.url : V2.Globals.BaseURL + "V2/scripts/ajax/call.php?call_arguments=" + obj_options.class + "|" + obj_options.method;
	this.success = ( typeof obj_options.success !== "undefined" ) ? obj_options.success : function( data ){ console.log( data ); };
	this.error = ( typeof obj_options.error !== "undefined" ) ? obj_options.error : function( error ){ console.log( error );};
	this.always = ( typeof obj_options.always !== "undefined" ) ? obj_options.always : function( data ){ console.log('AJAX-CALL');};
};

V2.Tool.Ajax.prototype = {
	call : function( postData ){
		var that  = this;
		$.ajax(
			{
				"method" : "POST",
				"url" : that.url,
				"dataType" : "json",
				"data" : ( typeof postData !== "undefined" ) ? postData : ""
			}
		)
		.done(function( data ){
			that.success( data );
		})
		.fail(function( error ){
			that.error( error );
		})
		.always(function( response ){
			that.always( response );
		});
	}
};