/*Namespace V2*/
window.V2 = {};
/*List Namespaces here*/
V2.Tool = {};
V2.Module = {};
V2.Module.General = {};
/*Overall Globals*/
V2.Globals = {};


if($('#shoutchat_holder').size() > 0){
    var $el = $('<span>Toggle Shoutbox</span>');
    $el.css({
        position : 'fixed',
        'z-index' : '999999',
        'display' : 'block',
        'padding' : '5px',
        'right' : '0',
        'bottom' : '0',
        'background-color' : 'white',
        'cursor' : 'pointer'
    });
    $el.on('click',function(){
        $('#shoutchat_holder').toggle();
    });
    $('body').append($el);
}